<template>
  <div>
    <el-table :data="table1" stripe>
      <el-table-column
        align="center"
        header-align="center"
        prop="company"
        label="公司"
      ></el-table-column>
      <el-table-column align="center" header-align="center" label="亚盘初盘">
        <el-table-column
          align="center"
          header-align="center"
          prop="fupsw"
          label="上盘水位"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="fpankou"
          label="盘口"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="fdownsw"
          label="下盘水位"
        ></el-table-column>
      </el-table-column>
      <el-table-column align="center" header-align="center" label="亚盘临盘">
        <el-table-column
          align="center"
          header-align="center"
          prop="lupsw"
          label="上盘水位"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="lpankou"
          label="盘口"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="ldownsw"
          label="下盘水位"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <el-table :data="table2" stripe>
      <el-table-column
        align="center"
        header-align="center"
        prop="company"
        label="公司"
      ></el-table-column>
      <el-table-column align="center" header-align="center" label="大小盘初盘">
        <el-table-column
          align="center"
          header-align="center"
          prop="fupsw"
          label="上盘水位"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="fpankou"
          label="盘口"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="fdownsw"
          label="下盘水位"
        ></el-table-column>
      </el-table-column>
      <el-table-column align="center" header-align="center" label="大小盘临盘">
        <el-table-column
          align="center"
          header-align="center"
          prop="lupsw"
          label="上盘水位"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="lpankou"
          label="盘口"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="ldownsw"
          label="下盘水位"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <el-table :data="table3" stripe>
      <el-table-column
        align="center"
        header-align="center"
        prop="company"
        label="公司"
      ></el-table-column>
      <el-table-column align="center" header-align="center" label="欧赔初盘">
        <el-table-column
          align="center"
          header-align="center"
          prop="fupsw"
          label="主赔"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="fpankou"
          label="平赔"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="fdownsw"
          label="客赔"
        ></el-table-column>
      </el-table-column>
      <el-table-column align="center" header-align="center" label="欧赔临盘">
        <el-table-column
          align="center"
          header-align="center"
          prop="lupsw"
          label="主赔"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="lpankou"
          label="平赔"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="ldownsw"
          label="客赔"
        ></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { asiaEuropeData } from "@/api/fenxi.js";

export default {
  data() {
    return {
      id: 0,
      table1: [],
      table2:[],
      table3:[]
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.asiaEuropeData();
  },
  methods: {
    asiaEuropeData() {
      asiaEuropeData(this.id).then(res => {
        this.table1 = res.data.filter(item=>item.star_type === '亚盘');
        this.table2 = res.data.filter(item=>item.star_type === '大小盘');
        this.table3 = res.data.filter(item=>item.star_type === '欧赔');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-table thead.is-group th {
  background: #16a085;
}

/deep/.el-table {
  thead {
    tr:nth-of-type(2) {
      th {
        background: #fdefd2;
        color: #000;
      }
      th:nth-of-type(n + 4) {
        background: #edf6f4;
        color: #000;
      }
    }
    tr:nth-of-type(3) {
      th {
        background: #fdefd2;
        color: #000;
      }
      th:nth-of-type(n + 7) {
        background: #edf6f4;
        color: #000;
      }
    }
  }
}
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.simply {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px;

  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
}
</style>
